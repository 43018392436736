<template>
  <div class="s-banner">
    <div class="s-banner__container">
      <div class="s-banner__left">
        <div class="s-banner__syn">
          Онлайн-школа «Синергия»
        </div>

        <h1
          class="s-banner__title title-primary"
          v-html="title"
        />

        <p class="s-banner__descr text-secondary">
          Учитесь из любой точки мира
          <br>
          на онлайн платформе и получите аттестат государственного образца
        </p>

        <div class="s-banner__btn-wrap">
          <img
            loading="lazy"
            class="s-banner__sticker"
            src="/v2/school/banner/sticker.svg"
            alt="free"
            width="121px"
            height="54px"
          >

          <button
            class="s-banner__btn m-btn m-btn--primary"
            @click="toggleLead1Modal"
          >
            <span class="s-banner__btn-content m-btn__content m-btn__content--primary">
              {{ partnersSchool ? 'получить доступ' : 'Войти на платформу' }}
            </span>
          </button>
        </div>
      </div>

      <div class="s-banner__rigth">
        <img
          loading="lazy"
          class="s-banner__img"
          src="/v2/school/banner/banner-img-mob.webp"
          alt="students"
          width="100%"
          height="auto"
        >

        <div class="parallax">
          <div class="parallax__wrap">
            <div
              ref="elem1"
              class="parallax__elem parallax__elem--1"
              data-rotate="18"
              data-translate="40"
            >
              <span class="parallax__hatch">#</span>контроша
            </div>

            <div
              ref="elem2"
              class="parallax__elem parallax__elem--2"
              data-rotate="-33"
              data-translate="30"
            >
              <span class="parallax__hatch">#</span>егэ
            </div>

            <div
              ref="elem3"
              class="parallax__elem parallax__elem--3"
              data-rotate="10"
              data-translate="5"
            >
              <span class="parallax__hatch">#</span>гос.аттестат
            </div>

            <div
              ref="elem4"
              class="parallax__elem parallax__elem--4"
              data-rotate="-20"
              data-translate="-30"
            >
              <span class="parallax__hatch">#</span>огэ
            </div>

            <div
              ref="elem5"
              class="parallax__elem parallax__elem--5"
              data-rotate="-22"
              data-translate="10"
            >
              <span class="parallax__hatch">#</span>школа
            </div>

            <img
              loading="lazy"
              class="parallax__bg"
              data-translate="1"
              src="/v2/school/banner/parallax-bg.svg"
              alt="students"
              width="100%"
              height="auto"
            >

            <img
              loading="lazy"
              class="parallax__img"
              data-translate="-1.8"
              src="/v2/school/banner/banner-img.webp"
              alt="students"
              width="100%"
              height="auto"
            >
          </div>
        </div>
      </div>

      <div class="s-banner__background background">
        <div class="background__left" />

        <div class="background__rigth" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MainBanner',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    partnersSchool() {
      return this.$route.name === 'online-school-partners';
    },
  },

  methods: {
    ...mapActions('modals', {
      toggleLead1Modal: 'toggleLead1',
    }),
  },
};
</script>

<style lang="scss" scoped>
.s-banner {
  position: relative;

  background-color: $white-color;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    background: url('/v2/main/banner/background.svg');

    pointer-events: none;

    @include media-down(tablet) {
      background-size: 950px;
    }
  }

  &__container {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;

    margin: 0 auto;
    padding: 193px 16px 80px;

    max-width: 1260px;
    width: 100%;

    @include media-down(laptop) {
      flex-direction: column;
      gap: 32px;

      padding: 96px 8px 56px;

      overflow: hidden;
    }

    @include media-between($tablet, $laptop) {
      padding: 126px 8px 56px;
    }

    @include media-down(tablet) {
      gap: 40px;
    }
  }

  &__left {
    flex-shrink: 0;

    width: 642px;
    position: relative;
    z-index: 1;

    @include media-down(desktop) {
      width: 580px;
    }

    @include media-down(laptop) {
      margin: 0 auto;

      max-width: 540px;
      width: auto;
    }
  }

  &__rigth {
    position: relative;
    z-index: 1;
  }

  &__syn {
    margin-bottom: 15px;

    font-size: 18px;
    font-weight: 700;
    color: $black;

    @include media-down(laptop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 8px;

      font-size: 16px;
    }
  }

  &__title {
    margin-bottom: 17px;

    max-width: 642px;

    letter-spacing: -1.3px;
    color: $black;

    @include media-up(laptop) {
      line-height: 65px;
    }

    @include media-down(desktop) {
      font-size: 55px;
    }

    @include media-down(laptop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      font-size: 35px;
    }
  }

  &__descr {
    margin-bottom: 46px;

    color: $black;

    @include media-down(desktop) {
      font-size: 28px;
    }

    @include media-down(laptop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 24px;

      font-size: 20px;
    }
  }

  &__btn-wrap {
    position: relative;

    width: fit-content;

    @include media-down(laptop) {
      margin: 0 auto;
    }
  }

  &__sticker {
    position: absolute;
    top: -37px;
    left: -11px;

    @include media-down(laptop) {
      top: auto;
      right: -40px;
      bottom: -33px;
      left: auto;

      transform: rotate(-13deg);
    }

    @include media-down(tablet) {
      bottom: -32px;
      right: -39px;
    }
  }

  &__btn {
    width: 320px;

    @include media-down(laptop) {
      display: block;

      margin: 0 auto;
    }

    @include media-down(tablet) {
      width: 256px;
      height: 65px;
    }
  }

  &__btn-content {
    padding: 18px 0 22px !important;

    background-color: #fbbd1e;
  }

  &__img {
    display: none;

    width: 98vw;

    @include media-down(tablet) {
      display: block;
    }

    @include media-down(mobile) {
      margin: 0 -30px;

      width: calc(100vw + 43px);
    }
  }

  &__a-circle {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.parallax {
  position: relative;
  top: -30px;
  left: 70px;

  width: 650px;
  height: 535px;

  @include media-between($laptop, $desktop) {
    left: 20px;

    width: 450px;
    height: 435px;
  }

  @include media-down(laptop) {
    top: 0;
    left: 0;

    margin: 0 auto;
  }

  @include media-down(tablet) {
    display: none;
  }

  &__elem {
    position: absolute;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: baseline;

    padding: 8px 15px;

    width: fit-content;
    height: 42px;

    border-radius: 200px;
    border: 2px solid rgba(36, 36, 36, 0.7);

    outline: 6px solid;

    font-size: 20px;
    font-weight: 600;
    color: $black;
    white-space: nowrap;

    will-change: transform;

    transition: 0.2s ease-out;

    &--1 {
      top: 37px;
      right: 90px;

      outline-color: #fb836f;

      background: #fb836f;

      transform: rotate(18deg);

      @include media-between($laptop, $desktop) {
        top: -3px;
        right: 30px;
      }
    }

    &--2 {
      top: 420px;
      right: 109px;

      outline-color: $blue;

      background-color: $blue;

      transform: rotate(-14deg);

      @include media-between($laptop, $desktop) {
        top: 290px;
        right: 9px;
      }
    }

    &--3 {
      right: 342px;
      bottom: 63px;

      outline-color: $violet;

      background-color: $violet;

      transform: rotate(30deg);

      @include media-between($laptop, $desktop) {
        right: 232px;
        bottom: 93px;
      }
    }

    &--4 {
      bottom: 210px;
      left: -10px;

      outline-color: $blue;

      background-color: $blue;

      transform: rotate(4deg);

      /* @include media-between($laptop, $desktop) {
        bottom: 240px;
        left: 10px;
      } */
    }

    &--5 {
      top: 50px;
      left: 100px;

      outline-color: #61ddbc;

      background-color: #61ddbc;

      transform: rotate(-22deg);

      @include media-between($laptop, $desktop) {
        top: 20px;
        left: 50px;
      }
    }
  }

  &__hatch {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }

  &__bg {
    position: absolute;
    top: 10px;
    right: -20px;

    width: 100%;

    will-change: transform;

    transition: 0.2s ease-out;
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;

    object-fit: contain;

    will-change: transform;

    transition: 0.2s ease-out;
  }
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;

  width: 100vw;

  overflow: hidden;

  transform: translateX(-50%);

  pointer-events: none;

  &__left {
    position: absolute;
    top: -230px;
    left: 0;

    width: 816px;
    height: 792px;

    border-radius: 816px;

    background: #8f00ff;

    filter: blur(310px);
    opacity: 0.2;

    @include media-down(tablet) {
      width: 107.7%;
      height: 47%;
      top: auto;
      bottom: -11.625%;
      left: -29.72%;

      filter: blur(150px);
      opacity: 0.15;
    }
  }

  &__rigth {
    position: absolute;
    top: -230px;
    right: 0;

    width: 816px;
    height: 792px;

    border-radius: 816px;

    background: #ff5c00;

    opacity: 0.2;
    filter: blur(310px);

    @include media-down(tablet) {
      width: 98.43%;
      height: 44.25%;
      top: -4.357%;
      left: 37.5%;

      filter: blur(150px);

      opacity: 0.15;
    }
  }

  @keyframes wave {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(100px, 550px);
    }

    75% {
      transform: translate(-100px, -50px);
    }

    100% {
      transform: translate(0, 0);
    }
  }
}
</style>
